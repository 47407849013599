<template>
  <div>
    <a-form-model
      class="mb-1"
      ref="addPermForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="sectionName" prop="sectionName" label="Permission section name">
            <a-input v-model="form.sectionName"
                     placeholder="e.g. 'banners' or 'users'"
                     @change="nameChange"
            >
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-2">
          <a-form-model-item ref="activityType" prop="activityType" label="Activity type">
            <a-select v-model="form.activityType" placeholder="please select activity type" @change="nameChange">
              <a-select-option
                v-for="type in ['view', 'create', 'edit', 'delete']"
                :key="type"
                :value="type"
                :disabled="permissionExists(form.sectionName + '.' + type)">
                <a-icon v-if="type === 'view'" type="eye" />
                <a-icon v-if="type === 'create'" type="plus" />
                <a-icon v-if="type === 'edit'" type="edit" />
                <a-icon v-if="type === 'delete'" type="delete" />
                {{ type.charAt(0).toUpperCase() + type.slice(1) }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="mt-0">
        <p class="font-size-16 mb-2"><a-icon type="team" /> Select roles:</p>
        <a-form-model-item prop="roles" class="mt-0">
          <a-checkbox-group v-model="form.roles"  name="roles" @change="roleChange">
            <a-checkbox v-for="role in roles_list" :key="role.id" :value="role.id" name="roles" @change="roleChange" :disabled="role.id === 1">
              <span>{{ role.name }}</span>
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </div>
      <hr>
      <div class="text-right">
        <a-button type="primary" @click="handleSubmit" :loading="loading">
          Create
        </a-button>
        <a-button style="margin-left: 1rem;" @click="closeAddPermModal">
          Cancel
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import store from 'store'

export default {
  name: 'addPerm.vue',
  mixins: [accessMix],
  computed: {
    loading() {
      return this.processing
    },
  },
  data() {
    return {
      processing: false,
      roles_list: store.get('app.users.roles'),
      permissions_list: store.get('app.users.permissions'),
      form: {
        roles: [1],
        name: '',
        sectionName: '',
        activityType: '',
      },
      rules: {
        sectionName: [{ required: true, message: 'Input permission section name', trigger: 'change' }],
        activityType: [{ required: true, message: 'Select activity type', trigger: 'change' }],
        // roles: [{ required: true, message: 'Please select roles', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
    // console.log(this.getPermissionTypesByName('banners'))
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.addPermForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.createPerm()
        } else {
          return false
        }
      })
    },
    closeAddPermModal() {
      this.$emit('closeModalFunction')
    },
    roleChange(e) {
      console.log(this.form.roles)
    },
    nameChange(e) {
      if (this.form.sectionName.length > 0 && this.form.activityType.length > 0) {
        this.form.name = this.form.sectionName + '.' + this.form.activityType
      }
    },
    createPerm() {
      const url = 'admin/permissions'
      return apiClient.post(url, this.form).then((response) => {
        this.permissions_list.push(response.data.data)
        store.set('app.users.permissions', this.permissions_list)
        this.$notification.success({
          message: 'Permission "' + response.data.data.name + '" successfully created',
          description: '',
        })
        this.processing = false
        this.$emit('submitModalFunction', response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new permission',
          description: error.message,
        })
        this.processing = false
      })
    },
  },
}
</script>

<style scoped>

</style>
